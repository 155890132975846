/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'

import { animated, useSpring } from 'react-spring'

import Slide from 'react-reveal/Slide'
import Fade from 'react-reveal/Fade'

import { globalCss, variables, colors } from '../styles/global'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SpacerPicture from '../components/SpacerPicture'

const Repertoire = ({ data }: any) => {
  const repertoireData = data.repertoireData.edges[0].node.frontmatter

  return (
    <Layout hideMenu={false} showButton>
      <SEO
        title="Repertoirelijst"
        description="Deze knallers kun je van ons verwachten..."
      />
      <div
        className="container"
        css={css`
          margin: auto;
          max-width: 1000px;
          background-color: ${colors.black};
          color: ${colors.white};
          padding: 100px ${variables.HPaddingDesktop}px 80px
            ${variables.HPaddingDesktop}px;
          ${variables.mobile} {
            padding: 30px ${variables.HPaddingMobile}px 80px
              ${variables.HPaddingMobile}px;
          }
        `}
      >
        <h1
          css={css`
            font-size: 4rem;
            ${variables.mobile} {
              font-size: 3rem;
            }
          `}
        >
          Repertoire
        </h1>
        <p>
          Hieronder zie je een greep uit de nummers die we spelen. Veel van de
          nummers spelen we in medley-vorm, wat wil zeggen dat er op een hoog
          tempo veel leuk, dansbaar materiaal langskomt. Het beste van een band
          en een DJ in een!
        </p>

        <p>
          Staat je favoriete nummer er niet bij? Wij studeren hem met veel
          plezier voor je in! Bij elk optreden mag je een of twee verzoeknummers
          opgeven, die de band instudeert speciaal voor jouw feest!
        </p>
        <p
          css={css`
            margin-bottom: -30px;
            margin-top: 80px;
          `}
        >
          <i>Klik op een genre om de nummers te zien</i>
        </p>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            width: 100%;
            flex-wrap: wrap;
            padding-bottom: 2rem;
          `}
        >
          <div
            css={css`
              width: 46%;
              ${variables.mobile} {
                width: 100%;
              }
            `}
          >
            {repertoireData.categorie.map((item: any, index: number) => {
              if (index < repertoireData.categorie.length / 2) {
                return (
                  <RepertoireCategorie
                    genre={item.genre}
                    songs={item.songs}
                    key={index}
                  />
                )
              }
            })}
          </div>
          <div
            css={css`
              width: 46%;
              ${variables.mobile} {
                width: 100%;
              }
            `}
          >
            {repertoireData.categorie.map((item: any, index: number) => {
              if (index >= repertoireData.categorie.length / 2) {
                return (
                  <RepertoireCategorie
                    genre={item.genre}
                    songs={item.songs}
                    key={index}
                  />
                )
              }
            })}
          </div>
        </div>
      </div>
      <div
        css={css`
          width: full;
          max-width: 1000px;
          margin: auto;
          margin-bottom: 250px;
        `}
      >
        <SpacerPicture
          sources={['/fotos/bannernieuw4.webp', '/fotos/bannernieuw4.jpg']}
        />
      </div>
    </Layout>
  )
}

type repertoireProps = {
  genre: string
  songs: string
}

function RepertoireCategorie({ genre, songs }: repertoireProps) {
  const [open, setOpen] = useState(false)

  const styleProp = useSpring({
    lineHeight: open ? '1.1rem' : '0rem',
    opacity: open ? 1 : 0,
  })

  const marginProp = useSpring({
    marginBottom: open ? '0px' : '-50px',
  })

  return (
    <animated.div
      css={css`
        width: 100%;
      `}
      style={marginProp}
    >
      <h4
        css={css`
          position: relative;
          display: inline-block;
          &:hover {
            cursor: pointer;
          }
          &:hover h4 {
            clip-path: circle(100% at 50% 50%);
          }
        `}
        onClick={() => setOpen(!open)}
        role="button"
      >
        {genre}
        <h4
          css={css`
            pointer-events: none;
            position: absolute;
            margin-top: 0;
            left: 0;
            top: 0;
            text-shadow: 1px 1px ${colors.lightBlue},
              -1px 1px ${colors.lightBlue}, 1px -1px ${colors.lightBlue},
              -1px -1px ${colors.lightBlue}, 0px 0px 6px ${colors.lightBlue};
            clip-path: circle(0% at 50% 50%);
            transition: clip-path 0.3s;
          `}
        >
          {genre}
        </h4>
      </h4>
      <animated.p
        css={css`
          white-space: pre-line;
        `}
        style={styleProp}
        dangerouslySetInnerHTML={{ __html: songs }}
      ></animated.p>
    </animated.div>
  )
}

export default Repertoire

export const query = graphql`
  query PageEnQuery {
    repertoireData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "repertoire" } } }
    ) {
      edges {
        node {
          frontmatter {
            categorie {
              genre
              songs
            }
          }
        }
      }
    }
  }
`
