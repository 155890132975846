/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import React from 'react'

export default function SpacerPicture({ sources }: { sources: string[] }) {
  return (
    <picture
      css={css`
        width: 100%;
        height: auto;
      `}
    >
      <source srcSet={sources[0]} type="image/webp" />
      <source srcSet={sources[1]} type="image/jpeg" />
      <img
        src={sources[0]}
        alt="foto"
        css={css`
          width: 100%;
          height: auto;
          display: block;
        `}
      />
    </picture>
  )
}
